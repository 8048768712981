import { create } from 'zustand'

interface Store {
  modalGetApp: {
    show: boolean
    onOpen(): void
    onDismiss(): void
  }
  modalShortcutsMenu: {
    show: boolean
    onOpen(): void
    onDismiss(): void
  }
  modalReferralLink: {
    show: boolean
    onOpen(): void
    onDismiss(): void
  }
  closeAll(): void
}

export const useStoreModals = create<Store>((set, get) => ({
  modalGetApp: {
    show: false,
    onOpen: () =>
      set({
        modalGetApp: {
          ...get().modalGetApp,
          show: true
        }
      }),
    onDismiss: () => set({ modalGetApp: { ...get().modalGetApp, show: false } })
  },
  modalShortcutsMenu: {
    show: false,
    onOpen: () =>
      set({
        modalShortcutsMenu: {
          ...get().modalShortcutsMenu,
          show: true
        }
      }),
    onDismiss: () =>
      set({
        modalShortcutsMenu: {
          ...get().modalShortcutsMenu,
          show: false
        }
      })
  },
  modalReferralLink: {
    show: false,
    onOpen: () =>
      set({
        modalReferralLink: {
          ...get().modalReferralLink,
          show: true
        }
      }),
    onDismiss: () =>
      set({
        modalReferralLink: {
          ...get().modalReferralLink,
          show: false
        }
      })
  },
  closeAll: () =>
    set({
      modalGetApp: { ...get().modalGetApp, show: false },
      modalShortcutsMenu: { ...get().modalShortcutsMenu, show: false },
      modalReferralLink: { ...get().modalReferralLink, show: false }
    })
}))
