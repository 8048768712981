import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import classNames from 'classnames'
import styles from './countdown.module.scss'
import { UseCountdown } from '../../hooks/misc/countdown'

interface CountdownProps {
  show: boolean
  countdown: UseCountdown
  theme?: 'white' | 'gray' | 'default'
  variant?: 'sm' | 'md' | 'lg'
  className?: string
}

export const Countdown: React.FC<CountdownProps> = ({
  show,
  countdown,
  theme = 'default',
  variant = 'lg',
  className
}) => {
  const { i18n } = useLingui()
  const { days, hours, minutes, seconds, ended } = countdown

  if (!show || ended) {
    return null
  }

  const themeClass = {
    [styles.white]: theme === 'white',
    [styles.gray]: theme === 'gray',
    [styles.default]: theme === 'default',
    [styles.sm]: variant === 'sm',
    [styles.md]: variant === 'md',
    [styles.lg]: variant === 'lg'
  }

  return (
    <div className={classNames(styles.clock, className)}>
      {days && days > 0 ? (
        <div
          className={classNames(styles.column, {
            [styles.days]: days > 0
          })}
        >
          <p className={classNames(styles.labelBig, themeClass)}>{days}</p>
          <p className={classNames(styles.labelSmall, themeClass)}>
            {i18n._(t`banner_days_display`)}
          </p>
        </div>
      ) : null}

      <div className={styles.column}>
        <p className={classNames(styles.labelBig, themeClass)}>{hours}</p>
        <p className={classNames(styles.labelSmall, themeClass)}>
          {i18n._(t`banner_hours_display`)}
        </p>
      </div>
      <p className={classNames(styles.colon, themeClass)}>:</p>

      <div className={styles.column}>
        <p className={classNames(styles.labelBig, themeClass)}>{minutes}</p>
        <p className={classNames(styles.labelSmall, themeClass)}>
          {i18n._(t`banner_mins_display`)}
        </p>
      </div>
      {days <= 0 && minutes ? (
        <p className={classNames(styles.colon, themeClass)}>:</p>
      ) : null}

      {days <= 0 && minutes ? (
        <div className={styles.column}>
          <p className={classNames(styles.labelBig, themeClass)}>{seconds}</p>
          <p className={classNames(styles.labelSmall, themeClass)}>
            {i18n._(t`banner_sec_display`)}
          </p>
        </div>
      ) : null}
    </div>
  )
}
