import { useContext, useMemo } from 'react'
import { useLingui } from '@lingui/react'
import { ContextShell } from '../../modules/context'
import { useCampaign } from './use-campaign'

export interface UseGlobalCampaign {
  campaign?: {
    id: string
    endDate: string
    countdownBannerImage: string
    profileBanner: any
  }
  isUserTarget: boolean
}

interface HasLanguage {
  lang: string
}

const findByLanguage = (
  options: HasLanguage[],
  language: string
): HasLanguage => {
  const result = options?.find(
    ({ lang }) => lang?.replace('-', '_') === language
  )
  return result || options?.[0]
}

export const useGlobalCampaign = (): UseGlobalCampaign => {
  const { i18n } = useLingui()
  const { user } = useContext(ContextShell)
  const { activeCampaign } = useCampaign()

  const isUserTarget = useMemo(() => !user?.subscription?.isPremium, [user])

  const dataCampaign = useMemo(() => {
    if (!activeCampaign || !activeCampaign?.metadata) {
      return undefined
    }

    const { profileBannerWeb, countdownBannerImage, endDate } =
      activeCampaign?.metadata

    return {
      id: activeCampaign?.id,
      endDate,
      profileBanner: findByLanguage(profileBannerWeb, i18n.locale),
      countdownBannerImage
    }
  }, [activeCampaign, i18n.locale])

  return {
    isUserTarget,
    campaign: dataCampaign
  }
}
