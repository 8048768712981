import { gql } from 'graphql-request'
import { ReferralLink as TypeReferralLink } from '../../../types/referral-links.types'

interface ReferralLinkCli {
  fetchReferralLinks(): Promise<ReferralLinkResponse>
}

export type ReferralLinkResponse = {
  referralLinks: TypeReferralLink[]
}

const ReferralLink = (graphQL: any): ReferralLinkCli => {
  const fetchReferralLinks = async (): Promise<any> => {
    const query = gql`
      query referralLink {
        referralLinks {
          id
        }
      }
    `
    const result = await graphQL({ query })
    return result
  }

  return {
    fetchReferralLinks
  }
}

export default ReferralLink
