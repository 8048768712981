import { gql } from 'graphql-request'

interface CampaignCli {
  getGlobalCampaign(): Promise<CampaignResponse>
}

type CampaignType = {
  id: string
  name: string
  couponCode: string
  discountPercentage: number
  eligiblePlans: string[]
  planNames: string[]
  endAt: string
  metadata: any
}

type CampaignResponse = {
  campaign: CampaignType
  flags?: {
    offerModeOnWeb: boolean
  }
}

const Campaign = (graphQL: any): CampaignCli => {
  const getGlobalCampaign = async (): Promise<any> => {
    const variables = {}
    const query = gql`
      query campaign {
        campaign {
          id
          name
          couponCode
          discountPercentage
          eligiblePlans
          planNames
          endAt
          metadata
        }
        flags: getFlags(flags: ["offerModeOnWeb"])
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    getGlobalCampaign
  }
}

export default Campaign
