/* eslint no-console: 0 */
import { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useEffectOnce } from 'react-use'
import ReactGA from '../../../lib/react-ga'
import { isDesktopApp } from '../../auth/use-authentication/providers/helpers'
import { useGetUtmStorage } from '../use-utm-storage/use-get-utm-storage'

import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import {
  EventParams,
  EventTrackParams,
  UseEvent
} from '../../../types/events.types'

interface Props {
  user: any
  userToken: any
}

type MessageType = {
  data: {
    type: string
    payload?: any
  }
}

const SESSION_STORAGE_KEY = 'moises:log_events'

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useEvent = ({ user }: Props): UseEvent => {
  const { query } = useRouter()
  const { getUTM } = useGetUtmStorage()
  const { id: userId, shouldIdentifyOnCIO = false } = useMemo(
    () => user || {},
    [user]
  )

  useEffectOnce(() => {
    if (query?.log_events) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    }
  })

  const sendEvent = useCallback(
    ({
      name,
      category,
      label,
      customAttributes,
      fbEventId,
      userId: userIdByParams
    }: EventParams) => {
      if (!userId?.length && !userIdByParams?.length) {
        return
      }

      const userIdLogged = userIdByParams || userId

      const extendedCustomAttributes = {
        ...customAttributes,
        user_id: userIdLogged,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        ...getUTM()
      }

      window?.rudderanalytics?.track(name, {
        category,
        label,
        ...{
          ...extendedCustomAttributes,
          shouldIdentifyOnCIO
        }
      })

      let extraFbParams
      if (fbEventId) extraFbParams = { eventID: fbEventId }

      window?.fbq?.(
        'track',
        name,
        {
          category,
          label: label || category,
          ...extendedCustomAttributes
        },
        extraFbParams
      )

      ReactGA.event({
        category,
        action: name,
        label: label || category,
        ...extendedCustomAttributes
      })

      if (sessionStorage.getItem(SESSION_STORAGE_KEY)) {
        console.group('event:', name)
        console.table({
          category,
          label,
          ...extendedCustomAttributes
        })
        console.groupEnd()
      }
    },
    [getUTM, shouldIdentifyOnCIO, userId]
  )

  const eventTrack = useCallback(
    async ({ data }: EventTrackParams) => {
      const extendedCustomAttributes = {
        ...data.payload,
        user_id: user?.id,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        timestamp: new Date().toISOString()
      }

      const event = {
        event: data.event,
        payload: extendedCustomAttributes
      }

      await MoisesCLI.eventTrack({
        data: event
      })
    },
    [user?.id]
  )

  useEffect(() => {
    if (!shouldIdentifyOnCIO) {
      const handleMessage = (event: MessageType): void => {
        const message = event.data

        if (['events-voice-studio'].includes(message.type)) {
          sendEvent(message.payload)
        }
      }

      window.addEventListener('message', handleMessage)
      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
    return () => {}
    // eslint-disable-next-line
  }, [sendEvent])

  return {
    sendEvent,
    eventTrack
  }
}
