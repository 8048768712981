import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ContextShell } from '../../modules/context'
import { useAnnouncements } from './use-announcements'

export type UseAnnouncement = {
  title: string | null
  description: string | null
  image: string | null
  name: string | null
  buttonText: string | null
  show: boolean
  onCloseBanner: (e: any) => void
  onClickBanner: () => void
  startAt: string | null
  showNewFeature: (url: string) => boolean
  onClickNewFeature: (url: string) => void
}

export const useAnnouncement = (): UseAnnouncement => {
  const [show, setShow] = useState(false)
  const { push } = useRouter()
  const {
    value: announcement,
    loadAnnouncements,
    dismissAnnouncement
  } = useAnnouncements()

  const {
    user,
    event: { sendEvent }
  } = useContext(ContextShell)

  const haveAnnouncement = useMemo(
    () => announcement !== undefined,
    [announcement]
  )

  useEffect(() => {
    if (haveAnnouncement) {
      setShow(true)
    }
  }, [haveAnnouncement])

  useEffect(() => {
    if (user?.id) {
      if (!announcement?.id) {
        loadAnnouncements()
      }
    }
  }, [announcement, loadAnnouncements, user?.id])

  const title = useMemo(() => announcement?.title || '', [announcement])

  const description = useMemo(
    () => announcement?.description || '',
    [announcement]
  )

  const image = useMemo(() => announcement?.image || '', [announcement])

  const name = useMemo(() => announcement?.name || '', [announcement])

  const buttonText = useMemo(
    () => announcement?.button?.label || '',
    [announcement]
  )

  const startAt = useMemo(() => announcement?.startAt || '', [announcement])

  const onDismiss = useCallback(() => {
    setShow(false)
    dismissAnnouncement({
      id: announcement?.id || '',
      elements: ['SIDEBANNER']
    })
  }, [dismissAnnouncement, announcement?.id])

  const onCloseBanner = useCallback(
    (e: any) => {
      e?.stopPropagation()
      onDismiss()
    },
    [onDismiss]
  )

  const onClickBanner = useCallback(() => {
    onDismiss()
    sendEvent({
      name: 'announcement_clicked',
      category: 'engagement',
      customAttributes: {
        announcement_name: name || 'announcement'
      }
    })
    push(announcement?.button?.url || '/')
  }, [push, announcement, onDismiss, sendEvent, name])

  const isUrlCorrect = useCallback(
    (url: string) => !!announcement?.button?.url?.includes(url),
    [announcement?.button?.url]
  )

  const showNewFeature = useCallback(
    (url: string) => (haveAnnouncement ? isUrlCorrect(url) : false),
    [isUrlCorrect, haveAnnouncement]
  )

  const onClickNewFeature = useCallback(
    (url: string) => {
      if (haveAnnouncement && isUrlCorrect(url)) {
        onDismiss()
      }
    },
    [haveAnnouncement, isUrlCorrect, onDismiss]
  )

  return {
    title,
    description,
    image,
    buttonText,
    show: show && haveAnnouncement,
    onCloseBanner,
    onClickBanner,
    startAt,
    name,
    showNewFeature,
    onClickNewFeature
  }
}
