import { useContext, useMemo } from 'react'
import { ContextShell } from '../../../modules/context'
import { Group } from '../../../types'

type UseDataManageGroup = {
  id: Group['id']
  group?: Group
  isEnabledGroupPlan: boolean
  isEnabledManagement: boolean
}

export const useDataManageGroup = (): UseDataManageGroup => {
  const { user } = useContext(ContextShell)
  const isPremium = useMemo(() => !!user?.subscription?.isPremium, [user])
  const isFree = useMemo(() => user?.subscription?.plan === 'free', [user])

  const group = useMemo(
    () => user?.groups?.find((i: Group) => !i.isPending),
    [user?.groups]
  )

  const id = useMemo(() => group?.id, [group])
  const isOwner = useMemo(() => !!(group && group?.isOwner), [group])

  const isEnabledGroupPlan = useMemo(
    () => !!user?.featureFlags?.webGroupPlan,
    [user]
  )

  const isIndividualSubscription = useMemo(
    () => user?.subscription?.subscriptionType === 'individual' && isPremium,
    [user, isPremium]
  )

  const isSubscriptionTrial = useMemo(() => {
    return user?.subscription?.details?.providerName === 'trial' && isPremium
  }, [user, isPremium])

  const isEnabledManagement = useMemo(
    () =>
      isEnabledGroupPlan &&
      !isSubscriptionTrial &&
      (isOwner || isFree || isIndividualSubscription),
    [
      isEnabledGroupPlan,
      isIndividualSubscription,
      isOwner,
      isFree,
      isSubscriptionTrial
    ]
  )

  return {
    id,
    group,
    isEnabledManagement,
    isEnabledGroupPlan
  }
}
