import classnames from 'classnames'
import React from 'react'
// @ts-ignore
import styles from './icon.module.scss'
import { Alert } from './icons/alert'
import { Arrow } from './icons/arrow'
import { ArrowFill } from './icons/arrow-fill'
import { AudioFiles } from './icons/audio-files'
import { Card } from './icons/card'
import { Check } from './icons/check'
import { CheckSimple } from './icons/check-simple'
import { Chevron } from './icons/chevron'
import { ChordsDetection } from './icons/chords-detection'
import { NoChordsDetection } from './icons/no-chords-detection'
import { Clock } from './icons/clock'
import { Close } from './icons/close'
import { Cloud } from './icons/cloud'
import { Code } from './icons/code'
import { Collections } from './icons/collections'
import { Computer } from './icons/computer'
import { CountIn } from './icons/count-in'
import { Crown } from './icons/crown'
import { Denoiser } from './icons/denoiser'
import { Download } from './icons/download'
import { DownloadApp } from './icons/download-app'
import { Drag } from './icons/drag'
import { Edit } from './icons/edit'
import { EditSimple } from './icons/edit-simple'
import { Email } from './icons/email'
import { Eye } from './icons/eye'
import { Flip } from './icons/flip'
import { Forward } from './icons/forward'
import { Goal } from './icons/goal'
import { Globe } from './icons/globe'
import { Help } from './icons/help'
import { Info } from './icons/info'
import { InstrumentBass } from './icons/instrument-bass'
import { InstrumentDrums } from './icons/instrument-drums'
import { InstrumentGuitar } from './icons/instrument-guitar'
import { InstrumentMic } from './icons/instrument-mic'
import { InstrumentMusicNote } from './icons/instrument-music-note'
import { InstrumentPiano } from './icons/instrument-piano'
import { InstrumentStrings } from './icons/instrument-strings'
import { InviteUser } from './icons/invite-user'
import { KeyBackspace } from './icons/key-backspace'
import { Keyboard } from './icons/keyboard'
import { KeyDown } from './icons/key-down'
import { KeyEnter } from './icons/key-enter'
import { KeyLeft } from './icons/key-left'
import { KeyRight } from './icons/key-right'
import { KeyShift } from './icons/key-shift'
import { KeySpacebar } from './icons/key-spacebar'
import { KeyUp } from './icons/key-up'
import { Link } from './icons/link'
import { Loader } from './icons/loader'
import { Lock } from './icons/lock'
import { Logout } from './icons/logout'
import { Lyrics } from './icons/lyrics'
import { Mastering } from './icons/mastering'
import { Maximize } from './icons/maximize'
import { Menu } from './icons/menu'
import { Metronome } from './icons/metronome'
import { Minus } from './icons/minus'
import { More } from './icons/more'
import { Music } from './icons/music'
import { MusicAdd } from './icons/music-add'
import { MusicRemove } from './icons/music-remove'
import { NoEye } from './icons/no-eye'
import { NoLyrics } from './icons/no-lyrics'
import { NoMusic } from './icons/no-music'
import { NoUpload } from './icons/no-upload'
import { Notification } from './icons/notification'
import { Pause } from './icons/pause'
import { Pen } from './icons/pen'
import { Pitch } from './icons/pitch'
import { Play } from './icons/play'
import { PlayFill } from './icons/play-fill'
import { Playlist } from './icons/playlist'
import { PlayOnRepeat } from './icons/play-on-repeat'
import { Plus } from './icons/plus'
import { PriceTag } from './icons/price-tag'
import { Refresh } from './icons/refresh'
import { Repeat } from './icons/repeat'
import { Search } from './icons/search'
import { Settings } from './icons/settings'
import { Shuffle } from './icons/shuffle'
import { SkipForward } from './icons/skip-forward'
import { Smartphone } from './icons/smartphone'
import { Smiley } from './icons/smiley'
import { Sections } from './icons/sections'
import { NoSections } from './icons/no-sections'
import { Spacebar } from './icons/spacebar'
import { SpatialAudio } from './icons/spatial-audio'
import { SpatialAudioV2 } from './icons/spatial-audio-v2'
import { Spliter } from './icons/spliter'
import { Star } from './icons/star'
import { Support } from './icons/support'
import { Trash } from './icons/trash'
import { Tuning } from './icons/tuning'
import { Upload } from './icons/upload'
import { User } from './icons/user'
import { UserError } from './icons/user-error'
import { UserGroup } from './icons/user-group'
import { UserGroup3 } from './icons/user-group-3'
import { UserGroup4 } from './icons/user-group-4'
import { UserVoice } from './icons/user-voice'
import { Volume0 } from './icons/volume-0'
import { Volume1 } from './icons/volume-1'
import { Volume2 } from './icons/volume-2'
import { Volume3 } from './icons/volume-3'
import { Zip } from './icons/zip'
import { Widget } from './icons/widget'
import { MusicList } from './icons/music-list'
import { AddLyrics } from './icons/add-lyrics'
import { Songwriting } from './icons/songwriting'
import { MusicNote } from './icons/music-note'
import { CheckboxCicle } from './icons/checkbox-cicle'
import { NoFile } from './icons/no-file'
import { AcousticGuitar } from './icons/acoustic-guitar'
import { Keys } from './icons/keys'
import { Cymbals } from './icons/cymbals'
import { HiHat } from './icons/hi-hat'
import { Kickdrum } from './icons/kickdrum'
import { Snare } from './icons/snare'
import { Toms } from './icons/toms'
import { Woodwind } from './icons/woodwind'
import { Dialogue } from './icons/dialogue'
import { Soundeffects } from './icons/sound-effects'
import { Soundtrack } from './icons/soundtrack'
import { Maracas } from './icons/maracas'
import { SocialFacebook } from './icons/social-facebook'
import { SocialGoogle } from './icons/social-google'
import { SocialApple } from './icons/social-apple'
import { SocialX } from './icons/social-x'
import { Circle } from './icons/circle'
import { CircleCheck } from './icons/circle-check'
import { InfoOutlined } from './icons/info-outlined'
import { Notes } from './icons/notes'
import { GiftBox } from './icons/gift-box'

interface IconProps {
  name?: string
  width?: number
  height?: number
  className?: string
  animation?: string
}

const getIcon = ({ name, width = 24, height = 24 }: IconProps): any => {
  const props = { width, height }
  const icons: { [key: string]: React.ReactNode } = {
    alert: <Alert {...props} />,
    'acoustic-guitar': <AcousticGuitar {...props} />,
    arrow: <Arrow {...props} />,
    'arrow-fill': <ArrowFill {...props} />,
    'audio-files': <AudioFiles {...props} />,
    'add-lyrics': <AddLyrics {...props} />,
    card: <Card {...props} />,
    check: <Check {...props} />,
    'check-simple': <CheckSimple {...props} />,
    'checkbox-cicle': <CheckboxCicle {...props} />,
    chevron: <Chevron {...props} />,
    'chords-detection': <ChordsDetection {...props} />,
    circle: <Circle {...props} />,
    'circle-check': <CircleCheck {...props} />,
    'no-chords-detection': <NoChordsDetection {...props} />,
    clock: <Clock {...props} />,
    close: <Close {...props} />,
    code: <Code {...props} />,
    cloud: <Cloud {...props} />,
    collections: <Collections {...props} />,
    computer: <Computer {...props} />,
    'count-in': <CountIn {...props} />,
    crown: <Crown {...props} />,
    denoiser: <Denoiser {...props} />,
    download: <Download {...props} />,
    'download-app': <DownloadApp {...props} />,
    drag: <Drag {...props} />,
    edit: <Edit {...props} />,
    'edit-simple': <EditSimple {...props} />,
    email: <Email {...props} />,
    eye: <Eye {...props} />,
    flip: <Flip {...props} />,
    forward: <Forward {...props} />,
    goal: <Goal {...props} />,
    globe: <Globe {...props} />,
    'gift-box': <GiftBox {...props} />,
    help: <Help {...props} />,
    info: <Info {...props} />,
    'info-outlined': <InfoOutlined {...props} />,
    dialogue: <Dialogue {...props} />,
    soundtrack: <Soundtrack {...props} />,
    'sound-effects': <Soundeffects {...props} />,
    cymbals: <Cymbals {...props} />,
    'hi-hat': <HiHat {...props} />,
    kickdrum: <Kickdrum {...props} />,
    snare: <Snare {...props} />,
    toms: <Toms {...props} />,
    maracas: <Maracas {...props} />,
    woodwind: <Woodwind {...props} />,
    'instrument-bass': <InstrumentBass {...props} />,
    'instrument-drums': <InstrumentDrums {...props} />,
    'instrument-guitar': <InstrumentGuitar {...props} />,
    'instrument-mic': <InstrumentMic {...props} />,
    'instrument-music-note': <InstrumentMusicNote {...props} />,
    'instrument-piano': <InstrumentPiano {...props} />,
    'instrument-strings': <InstrumentStrings {...props} />,
    'invite-user': <InviteUser {...props} />,
    'key-backspace': <KeyBackspace {...props} />,
    'key-down': <KeyDown {...props} />,
    'key-enter': <KeyEnter {...props} />,
    'key-left': <KeyLeft {...props} />,
    'key-right': <KeyRight {...props} />,
    'key-shift': <KeyShift {...props} />,
    'key-spacebar': <KeySpacebar {...props} />,
    'key-up': <KeyUp {...props} />,
    keyboard: <Keyboard {...props} />,
    keys: <Keys {...props} />,
    link: <Link {...props} />,
    loader: <Loader {...props} />,
    lock: <Lock {...props} />,
    logout: <Logout {...props} />,
    lyrics: <Lyrics {...props} />,
    'no-lyrics': <NoLyrics {...props} />,
    mastering: <Mastering {...props} />,
    maximize: <Maximize {...props} />,
    menu: <Menu {...props} />,
    metronome: <Metronome {...props} />,
    minus: <Minus {...props} />,
    more: <More {...props} />,
    music: <Music {...props} />,
    'music-note': <MusicNote {...props} />,
    'music-add': <MusicAdd {...props} />,
    'music-remove': <MusicRemove {...props} />,
    'music-list': <MusicList {...props} />,
    'no-eye': <NoEye {...props} />,
    'no-music': <NoMusic {...props} />,
    'no-upload': <NoUpload {...props} />,
    'no-file': <NoFile {...props} />,
    notification: <Notification {...props} />,
    notes: <Notes {...props} />,
    pen: <Pen {...props} />,
    pause: <Pause {...props} />,
    pitch: <Pitch {...props} />,
    tuning: <Tuning {...props} />,
    play: <Play {...props} />,
    'play-fill': <PlayFill {...props} />,
    'play-on-repeat': <PlayOnRepeat {...props} />,
    playlist: <Playlist {...props} />,
    plus: <Plus {...props} />,
    'price-tag': <PriceTag {...props} />,
    refresh: <Refresh {...props} />,
    // Figma calls this "refresh", although there is an existing "refresh" icon
    'refresh-2': <Repeat {...props} />,
    repeat: <Repeat {...props} />,
    search: <Search {...props} />,
    settings: <Settings {...props} />,
    shuffle: <Shuffle {...props} />,
    'skip-forward': <SkipForward {...props} />,
    smiley: <Smiley {...props} />,
    smartphone: <Smartphone {...props} />,
    songwriting: <Songwriting {...props} />,
    sections: <Sections {...props} />,
    'no-sections': <NoSections {...props} />,
    spacebar: <Spacebar {...props} />,
    'spatial-audio': <SpatialAudio {...props} />,
    'spatial-audio-v2': <SpatialAudioV2 {...props} />,
    'social-facebook': <SocialFacebook {...props} />,
    'social-google': <SocialGoogle {...props} />,
    'social-apple': <SocialApple {...props} />,
    'social-x': <SocialX {...props} />,
    spliter: <Spliter {...props} />,
    star: <Star {...props} />,
    support: <Support {...props} />,
    trash: <Trash {...props} />,
    upload: <Upload {...props} />,
    user: <User {...props} />,
    'user-error': <UserError {...props} />,
    'user-group': <UserGroup {...props} />,
    'user-group-3': <UserGroup3 {...props} />,
    'user-group-4': <UserGroup4 {...props} />,
    'user-voice': <UserVoice {...props} />,
    'volume-0': <Volume0 {...props} />,
    'volume-1': <Volume1 {...props} />,
    'volume-2': <Volume2 {...props} />,
    'volume-3': <Volume3 {...props} />,
    zip: <Zip {...props} />,
    widget: <Widget {...props} />
  }

  // eslint-disable-next-line no-prototype-builtins
  if (name && icons.hasOwnProperty(name)) {
    return icons[name]
  }

  return null
}

export const Icon: React.FC<IconProps> = ({
  className,
  name,
  width,
  height,
  animation
}) => (
  <span
    className={classnames(className, styles.icon, {
      [styles.animate]: !!animation,
      [styles.spin]: animation === 'spin'
    })}
  >
    {getIcon({ name, width, height })}
  </span>
)
