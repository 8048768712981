/* eslint-disable import/no-cycle */
import { GraphQLClient } from 'graphql-request'
import { getClientHeaders } from '../../config/index'
import { trigger } from '../events'
import { UserToken } from '../../types'
import Languages from './languages'
import User from './user'
import Campaign from './campaign'
import Announcement from './announcement'
import Playlist from './playlist'
import ReferralLink from './referral-link'
import Events from './events'

type MoisesCli = ReturnType<typeof Languages> &
  ReturnType<typeof Playlist> &
  ReturnType<typeof Announcement> &
  ReturnType<typeof ReferralLink> &
  ReturnType<typeof Campaign> &
  ReturnType<typeof Events> &
  ReturnType<typeof User> & {
    session: {
      authToken: UserToken
      apiEndpoint: string
    }
    auth: (authToken: UserToken) => void
    graphQL: ({
      query,
      variables
    }: {
      query: string
      variables?: any
    }) => Promise<any>
  }

export const Moises = (config: any): MoisesCli => {
  const session = {
    authToken: config.authToken || process.env.MOISES_AUTH_TOKEN,
    apiEndpoint: config.apiEndpoint || 'https://api.moises.ai'
  }

  const graphQLClient = new GraphQLClient(`${session.apiEndpoint}/graphql`, {
    credentials: 'include',
    mode: 'cors'
  })

  function auth(authToken: string | UserToken): void {
    session.authToken = authToken
  }

  async function graphQL({
    query,
    variables
  }: {
    query: string
    variables?: any
  }): Promise<any> {
    if (!session.authToken) {
      throw new Error('You need to set your apiKey')
    }

    try {
      const response = await graphQLClient.request(query, variables, {
        Authorization: session.authToken,
        ...getClientHeaders()
      })

      return response
    } catch (e: any) {
      const isAuthFailed = e?.response?.errors?.some(
        (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
      )

      if (isAuthFailed) {
        trigger('firebase:refresh-token')
      }

      console.log('UNAUTHENTICATED?', isAuthFailed) // eslint-disable-line no-console
      console.error('Error fetching graphql') // eslint-disable-line no-console
      throw new Error(e)
    }
  }

  const user = User(graphQL)
  const playlist = Playlist(graphQL)
  const announcement = Announcement(graphQL)
  const campaign = Campaign(graphQL)
  const languages = Languages(graphQL)
  const referralLink = ReferralLink(graphQL)
  const events = Events(graphQL)

  return {
    ...user,
    ...playlist,
    ...campaign,
    ...languages,
    ...announcement,
    ...referralLink,
    ...events,
    session,
    auth,
    graphQL
  }
}
