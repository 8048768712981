import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useGlobalCampaign } from './use-global-campaign'
import { usePersistentDismissible } from '../misc/use-persistent-dismissable'
import { useCountdown } from '../misc/countdown'
import { ContextShell } from '../../modules/context'

export interface UseCampaignCountdown {
  show: boolean
  title: string
  description: string
  image?: string
  countdown: ReturnType<typeof useCountdown>
  onClickBannerCountdown: () => void
  onCloseBannerCountdown: (e: any) => void
  buttonText: string | null
}

export const useCampaignCountdown = (
  interval?: number
): UseCampaignCountdown => {
  const { push } = useRouter()
  const { user } = useContext(ContextShell)
  const { campaign, isUserTarget } = useGlobalCampaign()

  const countdown = useCountdown({
    endDate: campaign?.endDate || Date.now(),
    interval: interval || 1000 * 60
  })

  const { show, onDismiss } = usePersistentDismissible({
    id: `countdown-${campaign?.id}`,
    type: 'session',
    enabled: !!campaign?.id
  })

  const onCloseBannerCountdown = useCallback(
    (e: any) => {
      e?.stopPropagation()
      onDismiss()
    },
    [onDismiss]
  )

  const onClickBannerCountdown = useCallback(() => {
    const pathBilling = user?.featureFlags?.webNewBilling
      ? '/billing/pricing/'
      : '/pricing'
    push(pathBilling)
  }, [push, user?.featureFlags?.webNewBilling])

  return {
    title: campaign?.profileBanner.title,
    description: campaign?.profileBanner.description,
    image: campaign?.countdownBannerImage,
    show: show && isUserTarget && !countdown?.ended,
    onCloseBannerCountdown,
    onClickBannerCountdown,
    countdown,
    buttonText: campaign?.profileBanner.cta
  }
}
