import React from 'react'
import { I18nContainer } from '../i18n-container'
import { RootContainer } from '../root-container'
import { AuthenticatedContainer } from '../authenticated-container'
import { ToastContainer } from '../toast-container'
import { ContextProvider } from '../../modules/context'

interface Props {
  children: React.ReactNode
}

export const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <ContextProvider>
      <I18nContainer>
        <RootContainer>
          <AuthenticatedContainer>
            <>
              <ToastContainer />
              {children}
            </>
          </AuthenticatedContainer>
        </RootContainer>
      </I18nContainer>
    </ContextProvider>
  )
}
