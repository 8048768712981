import { gql } from 'graphql-request'

interface EventCli {
  eventTrack(data: { data: { event: string; payload: any } }): Promise<any>
}

const Events = (graphQL: any): EventCli => {
  const eventTrack = async ({
    data
  }: {
    data: {
      event: string
      payload: any
    }
  }): Promise<any> => {
    const variables = {
      data
    }
    const query = gql`
      mutation ($data: EventTrackInput!) {
        eventTrack(data: $data)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return { eventTrack }
}

export default Events
